<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-group
            label="Registros por página"
          >
            <v-select
              v-model="perPage"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-form-group>
        </b-col>

        <!-- Search -->
        <b-col
          cols="6"
          md="6"
          class="topTable"
        >
          <div class="d-flex align-items-right justify-content-end">
            <b-button
              @click="$router.push({ name: 'add-prices', params: { id: '18' }})">
              Nueva cotización
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
      <!-- :items="fetchInvoices" -->
    <b-overlay
      :show="tableOverlay"
      rounded="sm"
    >
      <div id="main-table">
        <b-table
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="data"
          @row-clicked="onRowClicked"
          
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"

          ref="refInvoiceListTable"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No hay registros"
          class="position-relative"
        >

          <!-- Column: Id -->
          <template #cell(idQuote)="data">
            <div class="font-weight-bold">
              #{{ data.value }}
            </div>
          </template>

          <!-- Column: quoteDate -->
          <template #cell(quoteDate)="data">
              {{ new Date(data.value) | moment("DD/MM/YYYY, h:mm:ss a") }}
          </template>

          <template #cell(idStatus)="data">
            <!-- Compra -->
            <template v-if="data.value === 2">
              <b-badge
                pill
                variant="light-success"
              >
                Abierta
              </b-badge>
            </template>
            <!-- Venta -->
            <template v-if="data.value === 1">
              <b-badge
                pill
                variant="light-warning"
              >
              Cerrada
              </b-badge>
            </template>
          </template>

        </b-table>
      </div>
    </b-overlay>
    <br>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BOverlay, BFormGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from './invoiceStoreModule'



export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    ToastificationContent,
    BOverlay,
    vSelect,
    BFormGroup,
  },
  data() {
    return {
      tableColumns: [
        { key: 'idQuote', label: '#', sortable: true, thStyle: {width: '5em'} },
        // { key: 'invoiceStatus', sortable: true },
        // { key: 'client', label: 'Cliente', sortable: true },
        // { key: 'mineral', label: 'Mineral', sortable: true },
        // { key: 'id', sortable: true, formatter: val => `$${val}` },
        { key: 'quoteDate', label: 'Fecha de modificación', sortable: true },
        { key: 'idStatus', label: 'Estatus', sortable: true, thStyle: {width: '15em'} },
        // { key: 'balance', sortable: true },
      ],
      perPage: 5,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      onFiltered: [],
      tableOverlay: true,

      data: [],
    }
  },
  created() {
    this.getQuotes();
  },
  methods: {
    getQuotes() {
      this.$axios.get(this.$services + "quotes/get_quotes", {
        params: {
          operation: "2",
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.data = res.data.data;
          this.totalRows = res.data.data.length;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }).catch(error => this.$verifyToken(error.response.data.data)).finally(() => {
        this.tableOverlay = false;
      });
    },
    onRowClicked(item) {
      this.$router.push({ name: 'edit-prices', params: { id: item.idQuote }})
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-orders'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchInvoices,
    } = useInvoicesList()

    return {
      fetchInvoices,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

br {
  user-select: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/customHelios.scss';
</style>
